body {
    background-color: #000000;
}

.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

.App-header, .App-footer {
    min-height: 80vh;
    background-color: #212121;
    font-size: calc(10px + 2vmin);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
}

.App-footer {
    background-color: #000000;
    min-height: 10vh;
    font-size: calc(4px + 1vmin);
}

.App-link {
    color: #f15922;
    text-decoration: underline;
}

.App-link:hover {
    color: #bb451a;
    text-decoration: underline;
}

.navbar {
    padding: 18px;
    background-color: #000000;
    text-align: left;
    font-size: 24px;
}

.navbar a {
    color: #6cc8bf;
}

.navbar a:hover {
    color: #3daa9e;
}